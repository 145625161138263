<template>
  <div class="site-parking-box">
    <div class="header">
      <img
        @click="goback"
        class="back"
        src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/back.png"
        alt=""
      />
      <div class="title">场内挪车</div>
    </div>

    <!-- 内容 -->
    <div class="content" v-if="dataCard.length > 0 && loaded">
      <div
        :class="listNum === 5 ? 'list list-active' : 'list'"
        @click="handleClickCard(5)"
        v-if="dataCard.includes(5)"
      >
        <img :src="listNum === 5 ? loadC : loadD" alt="" />
        <p>去装车点</p>
      </div>
      <div
        :class="listNum === 7 ? 'list list-active' : 'list'"
        @click="handleClickCard(7)"
        v-if="dataCard.includes(7)"
      >
        <img :src="listNum === 7 ? startC : startD" alt="" />
        <p>去发车点</p>
      </div>
      <div
        :class="listNum === 8 ? 'list list-active' : 'list'"
        @click="handleClickCard(8)"
        v-if="dataCard.includes(8)"
      >
        <img :src="listNum === 8 ? unloadC : unloadD" alt="" />
        <p>去卸车点</p>
      </div>
      <div
        :class="listNum === 6 ? 'list list-active' : 'list'"
        @click="handleClickCard(6)"
        v-if="dataCard.includes(6)"
      >
        <img :src="listNum === 6 ? stopC : stopD" alt="" />
        <p>去停车点</p>
      </div>
    </div>

    <!-- 空数据 -->
    <div class="empty" v-else-if="dataCard.length <= 0 && loaded">
      <img src="../../assets/parking/empty.png" alt="" />
      <p>暂无数据</p>
    </div>
  </div>
</template>

<script>
import { getParkMoveCarPOI } from '@/api/apiv2';

import loadD from '../../assets/parking/load-d.png';
import startD from '../../assets/parking/start-d.png';
import unloadD from '../../assets/parking/unload-d.png';
import stopD from '../../assets/parking/stop-d.png';

import loadC from '../../assets/parking/load-c.png';
import startC from '../../assets/parking/start-c.png';
import unloadC from '../../assets/parking/unload-c.png';
import stopC from '../../assets/parking/stop-c.png';

export default {
  name: 'onSiteParking',
  components: {},
  data() {
    return {
      listNum: 0,
      loadD,
      loadC,
      startD,
      startC,
      unloadD,
      unloadC,
      stopD,
      stopC,
      dataCard: [],
      loaded: false,
    };
  },

  mounted() {
    this.getOnSiteParkingList();
  },
  methods: {
    goback() {
      window.location.href = 'neolix://parking_trim';
    },

    // 点击卡片
    handleClickCard(num) {
      this.listNum = num;
      this.$router.push({
        path: 'choosePoint',
        query: {
          poiType: num,
          parkCode: this.$route.query.parkCode,
          userId: this.$route.query.userId,
          vin: this.$route.query.vin,
        },
      });
    },

    //获取列表
    getOnSiteParkingList() {
      this.$loadingCircle.start();
      getParkMoveCarPOI({
        parkCode: this.$route.query.parkCode,
      })
        .then((res) => {
          let { parkCode, userId, vin } = this.$route.query;
          this.loaded = true;
          if (res.code === '43001') {
            window.location.href = `./Parking?parkCode=${parkCode}&userId=${userId}&vin=${vin}`;
          }
          console.log(res, '..re');
          this.dataCard = res.data;
        })
        .finally(() => {
          this.loaded = true;
          this.$loadingCircle.end();
        });
    },
  },
};
</script>

<style scoped lang="scss">
.site-parking-box {
  width: 100%;
  height: 100%;
  background: rgba(245, 246, 249, 1);
  font-family: PingFangSC-Medium;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0;
  font-weight: 600;

  .header {
    height: 0.8rem;
    line-height: 0.8rem;
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    background: #ffffff;
    z-index: 100;
    .back {
      width: 0.64rem;
      height: 0.64rem;
      margin: 0.08rem 0.22rem;
    }
    .title {
      font-size: 0.3rem;
      color: #000000;
      letter-spacing: 0;
      font-weight: 500;
      text-align: center;
      width: 100%;
      min-width: 2rem;
      margin-right: 1.08rem;
    }
  }

  .empty {
    width: 100%;
    img {
      width: 2.6rem;
      height: 2.6rem;
      display: block;
      margin: 3rem auto 0;
    }
    p {
      font-size: 0.32rem;
      text-align: center;
      font-weight: 400;
    }
  }
  .content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1.28rem 0.48rem;
    .list {
      width: 3.1rem;
      height: 2.4rem;
      opacity: 1;
      border-radius: 0.12rem;
      background: rgba(255, 255, 255, 1);
      margin-bottom: 0.34rem;
      img {
        width: 0.96rem;
        height: 0.96rem;
        margin: 0.32rem 0 0.29rem 0.32rem;
      }
      p {
        font-size: 0.36rem;
        font-weight: 600;
        color: rgba(0, 0, 0, 1);
        margin-left: 0.32rem;
      }
    }
    .list-active {
      background: rgba(67, 110, 255, 1);
      p {
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}
</style>
